import { useStaticQuery, graphql } from "gatsby";

export const useListServicesQuery = () => {
  const data = useStaticQuery(graphql`
  query ListServices {
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {name: {eq: "Services"}}}}, status: {eq: "publish"}}
      limit: 15
      sort: {order: DESC, fields: date}
    ) {
      edges {
        node {
          title
          uri
          id
          slug
          excerpt
          status
          link
        }
      }
    }
  }
  
  
  `)

  return data;

}